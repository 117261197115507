<template>
 <v-container fluid class="py-3 px-5">
   <v-row>
     <v-col cols="12" class="pb-1">
       <p class="ma-1">
        PRODUCTOS
       </p>
     </v-col>
   </v-row>
   <v-row>
     <v-col cols="12" class="pt-1">
       <ProductTable :headers="headers" :boards="boards">

       </ProductTable>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import ProductTable from '@/components/ProductTable.vue' 
export default {
  name: 'Products',
  components: {
    ProductTable
  },
  data() {
    return {
      headers: {
        product: [
          {
            text: 'Codigo',
            align: 'start',
            value: 'code',
          },
          {
            text: 'Nombre',
            align: 'start',
            value: 'name',
          },
          {
            text: 'Nombre Producto',
            align: 'start',
            value: 'product_name',
          },
          {
            text: 'Subfamilia',
            align: 'start',
            value: 'subfamilyId.name',
          },
          {
            text: 'Descripcion',
            align: 'start',
            value: 'description',
          },
          {
            text: 'Nombre Corto',
            align: 'start',
            value: 'short_name',
          },
          {
            text: 'Codigo Viator',
            align: 'start',
            value: 'product_code',
          },
          {
            text: 'Cpdigo WebPage',
            align: 'start',
            value: 'product_code2',
          },
          {
            text: 'Precio Standar',
            align: 'start',
            value: 'price',
          },
          {
            text: 'Politicas especiales',
            align: 'start',
            value: 'policy',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ],
      },
      boards: {
        product: 'product',
      }
    }
  },
}
</script>

<style>

</style>